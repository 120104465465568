import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import "firebase/firestore";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import "./schoolResult.css";
import Navbar from "../navbar/Navbar";
import ReportDialog from "../ReportDialog/ReportDialog";
import { useUserAuth } from "../../context/UserAuthContext";
import ReportIcon from "@mui/icons-material/Report";

const CompanyResult = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const item = location.state;
  const [clubsAvg, setClubsAvg] = useState(0);
  const [facilitiesAvg, setFacilitiesAvg] = useState(0);
  const [managementAvg, setManagementAvg] = useState(0);
  const [happinessAvg, setHappinessAvg] = useState(0);
  const [internetAvg, setInternetAvg] = useState(0);
  const [locationAvg, setLocationAvg] = useState(0);
  const [opportunitiesAvg, setOpportunitiesAvg] = useState(0);
  const [reputationAvg, setReputationAvg] = useState(0);
  const [safetyAvg, setSafetyAvg] = useState(0);
  const [socialAvg, setSocialAvg] = useState(0);

  const [isReportDialogOpen, setReportDialogOpen] = useState(false);
  const { user } = useUserAuth();
  const reporterId = user?.uid;
  const [commentId, setCommentId] = useState("");
  const navigate = useNavigate();

  const handleReportButtonClicked = (cmtId) => {
    if (user) {
      setReportDialogOpen(true);
      setCommentId(cmtId);
    } else {
      navigate("/login");
    }
  };

  const handleReportSubmit = async (reason) => {
    console.log("Reported with reason:", reason);
    console.log("user_id: ", reporterId);

    try {
      // Reference to the specific report by a user for a comment
      const reportRef = doc(
        db,
        "reportedComments",
        commentId,
        "reports",
        reporterId
      );
      const reportSnap = await getDoc(reportRef);

      // If a report from this user for this comment exists, return
      if (reportSnap.exists()) {
        alert("You have already reported this comment.");
        return "You have already reported this comment.";
      }

      // Otherwise, create or update the report
      await setDoc(reportRef, {
        reporterId: reporterId,
        reason: reason,
        timestamp: new Date(),
      });

      alert("Comment reported successfully!");
      return "Comment reported successfully!";
    } catch (error) {
      alert("Error reporting comment:", error.message);
      return "An error occurred while reporting the comment.";
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      let list = [];
      let happinessSum = 0;
      let internetSum = 0;
      let managementSum = 0;
      let clubSum = 0;
      let reputationSum = 0;
      let opportunitieSum = 0;
      let socialSum = 0;
      let facilitiSum = 0;
      let locationSum = 0;
      let safetySum = 0;
      let count = 0;

      try {
        const q = query(
          collection(db, "CompanyRatings"),
          where("id", "==", item.id),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          happinessSum += doc.data().happiness;
          internetSum += doc.data().internet;
          managementSum += doc.data().management;
          clubSum += doc.data().clubs;
          reputationSum += doc.data().reputation;
          opportunitieSum += doc.data().opportunities;
          socialSum += doc.data().social;
          facilitiSum += doc.data().facilities;
          locationSum += doc.data().location;
          safetySum += doc.data().safety;

          count++;
          list.push({ id: doc.id, commentId: doc.id, ...doc.data() });
        });
        setHappinessAvg(happinessSum / count);
        setInternetAvg(internetSum / count);
        setManagementAvg(managementSum / count);
        setClubsAvg(clubSum / count);
        setReputationAvg(reputationSum / count);
        setOpportunitiesAvg(opportunitieSum / count);
        setSocialAvg(socialSum / count);
        setFacilitiesAvg(facilitiSum / count);
        setLocationAvg(locationSum / count);
        setSafetyAvg(safetySum / count);

        setData(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [item.id]);

  const calculateOverallRating = (rating) => {
    const categories = [
      "reputation",
      "location",
      "opportunities",
      "facilities",
      "internet",
      "management",
      "clubs",
      "social",
      "happiness",
      "safety",
    ];
    const sum = categories.reduce((acc, category) => acc + rating[category], 0);
    return sum / categories.length;
  };

  const RatingItem = ({ label, value }) => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="body2">
          <strong>{label}</strong>
        </Typography>
        <Rating
          value={value}
          readOnly
          size="small"
          sx={{
            fontSize: "1.4rem",
          }}
        />
      </Box>
    );
  };

  const CompanyRatingCard = ({ rating, handleReportButtonClicked }) => {
    const overallRating = calculateOverallRating(rating);

    return (
      <Card sx={{ mb: 2, boxShadow: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Box
                textAlign="center"
                border={1}
                borderColor="divider"
                p={2}
                borderRadius={2}
              >
                <Typography variant="h6">Overall</Typography>
                <Typography variant="h3">{overallRating.toFixed(1)}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Chip
                  label={overallRating > 3 ? "GREAT" : "AWFUL"}
                  color={overallRating > 3 ? "success" : "error"}
                />
                <Typography variant="subtitle2">
                  {rating.timestamp.toDate().toDateString()}
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                {rating.review}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={() => handleReportButtonClicked(rating.commentId)}
                startIcon={<ReportIcon />}
                sx={{ mb: 2 }}
              >
                Report
              </Button>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <RatingItem label="Reputation" value={rating.reputation} />
                  <RatingItem label="Location" value={rating.location} />
                  <RatingItem
                    label="Opportunities"
                    value={rating.opportunities}
                  />
                  <RatingItem label="Facilities" value={rating.facilities} />
                  <RatingItem label="Internet" value={rating.internet} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RatingItem label="Management" value={rating.management} />
                  <RatingItem label="Clubs" value={rating.clubs} />
                  <RatingItem label="Social" value={rating.social} />
                  <RatingItem label="Happiness" value={rating.happiness} />
                  <RatingItem label="Safety" value={rating.safety} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Navbar showLinks={false} showSearchBar={true} />

      <div className="action-btns">
        <Link
          style={{ textDecoration: "none" }}
          to="/rateCompany"
          state={item.id}
        >
          <Button variant="contained" color="primary">
            <b> Rate {item.name}</b>
          </Button>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/addCompany">
          <Button variant="contained" color="success">
            <b> Add New Company</b>
          </Button>
        </Link>
      </div>

      {data.length === 0 ? (
        <div>
          <h1 style={{ textAlign: "center", color: "red" }}>NO RATING YET</h1>
        </div>
      ) : (
        <div className="school-results-container">
          <h1>{item.name}</h1>
          <a href={item.website} target="_blank" style={{ marginBottom: "2%" }}>
            Visit Official website
          </a>
          <div className="school-summary-container">
            <div className="school-summary-half">
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Happiness: 😊</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(happinessAvg).toFixed(1)}</h3>
                </div>
              </div>
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Internet: 🌐</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(internetAvg).toFixed(1)}</h3>
                </div>
              </div>
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Management: 👔</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(managementAvg).toFixed(1)}</h3>
                </div>
              </div>
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Clubs: 🏢 🤝</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(clubsAvg).toFixed(1)}</h3>
                </div>
              </div>
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Reputation: 🌟</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(reputationAvg).toFixed(1)}</h3>
                </div>
              </div>
            </div>

            <div className="school-summary-half">
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Opportunities: 🚪</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(opportunitiesAvg).toFixed(1)}</h3>
                </div>
              </div>
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Social: 👥</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(socialAvg).toFixed(1)}</h3>
                </div>
              </div>
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Facilities: 🏢</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(facilitiesAvg).toFixed(1)}</h3>
                </div>
              </div>
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Location: 📍</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(locationAvg).toFixed(1)}</h3>
                </div>
              </div>
              <div className="school-summary-child">
                <div className="school-summary-child-left">
                  <p>Safety: 🛡️</p>
                </div>
                <div className="school-summary-child-right">
                  <h3>{parseFloat(safetyAvg).toFixed(1)}</h3>
                </div>
              </div>
            </div>
          </div>
          <Container
            maxWidth="lg"
            sx={{ px: { xs: 2, sm: 3, md: 4 }, marginTop: "16px" }}
          >
            {data.map((rating) => (
              <CompanyRatingCard
                key={rating.id}
                rating={rating}
                handleReportButtonClicked={handleReportButtonClicked}
              />
            ))}
          </Container>
        </div>
      )}
      <ReportDialog
        open={isReportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        onSubmit={handleReportSubmit}
      />
    </div>
  );
};

export default CompanyResult;
